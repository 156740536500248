var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "form", attrs: { name: "form" } },
    [
      _c("e-sidebar", {
        attrs: {
          id: "sidebar-stock-inversion",
          title: _vm.$t("Nova Inversão de Estoque"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: {
          save: _vm.saveStockInversion,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: { id: "inversion-store" },
                          model: {
                            value: _vm.productForm.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "storeId", $$v)
                            },
                            expression: "productForm.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "inversion-product-from",
                            label: _vm.$t("Produto de Origem"),
                            "only-active": true,
                          },
                          model: {
                            value: _vm.productForm.skuFromId,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "skuFromId", $$v)
                            },
                            expression: "productForm.skuFromId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "inversion-product-to",
                            label: _vm.$t("Produto de Destino"),
                            "only-active": true,
                          },
                          model: {
                            value: _vm.productForm.skuToId,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "skuToId", $$v)
                            },
                            expression: "productForm.skuToId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "quantity",
                            type: "switch",
                            label: _vm.$t("Destino com quantidade diferente?"),
                            instruction: _vm.$t(
                              "STOCK_INVERSION.VIEW.INSTRUCTIONS.INPUT_HAS_DIFF"
                            ),
                          },
                          model: {
                            value: _vm.layoutForm.hasQuantityDiff,
                            callback: function ($$v) {
                              _vm.$set(_vm.layoutForm, "hasQuantityDiff", $$v)
                            },
                            expression: "layoutForm.hasQuantityDiff",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "quantity",
                            type: "number",
                            label: _vm.$t("Quantidade"),
                            validation: "required|min:1",
                          },
                          model: {
                            value: _vm.productForm.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "quantity", _vm._n($$v))
                            },
                            expression: "productForm.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.layoutForm.hasQuantityDiff
                      ? _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "quantityTo",
                                type: "number",
                                label: _vm.$t("Quantidade Destino"),
                                validation: "required|min:1",
                                instruction: _vm.$t(
                                  "STOCK_INVERSION.VIEW.INSTRUCTIONS.INPUT_QUANTITY_TO"
                                ),
                              },
                              model: {
                                value: _vm.productForm.quantityTo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.productForm,
                                    "quantityTo",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "productForm.quantityTo",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "comments",
                            type: "textarea",
                            label: _vm.$t("Observação"),
                            validation: "^required|min:25,length",
                          },
                          model: {
                            value: _vm.productForm.comments,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "comments", _vm._n($$v))
                            },
                            expression: "productForm.comments",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }