var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: {
                      id: "filter-store",
                      required: false,
                      placeholder: _vm.$t("Todas"),
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "filter-product-from",
                      required: false,
                      label: _vm.$t("Produto Origem"),
                    },
                    model: {
                      value: _vm.filters.skuFromId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuFromId", $$v)
                      },
                      expression: "filters.skuFromId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "filter-product-to",
                      required: false,
                      label: _vm.$t("Produto Destino"),
                    },
                    model: {
                      value: _vm.filters.skuToId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuToId", $$v)
                      },
                      expression: "filters.skuToId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "period",
                      type: "date-range-picker",
                      label: _vm.$t("Período"),
                      "time-picker": false,
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockInversions",
            staticClass: "mb-1 bordered",
            attrs: {
              "show-empty": "",
              striped: "",
              responsive: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_INVERSION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.stockInversions,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(store)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      [
                        _c("e-instructions", {
                          attrs: {
                            title: _vm.$t("Informações adicionais"),
                            target: "instruction_stock_" + row.item.id,
                            instructions: [
                              {
                                text:
                                  _vm.$t("Responsável") +
                                  ": " +
                                  row.item.createdBy,
                              },
                              {
                                text: _vm.$t("Obs") + ": " + row.item.comments,
                              },
                            ],
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("storeName")(row.item.store)) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(skuFromName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.skuFrom.id,
                        "product-id": item.skuFrom.productId,
                        "product-name": item.skuFrom.name,
                        ean: item.skuFrom.ean,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(skuToName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.skuTo.id,
                        "product-id": item.skuTo.productId,
                        "product-name": item.skuTo.name,
                        ean: item.skuTo.ean,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("stock-inversion-sidebar", {
        ref: "sidebar",
        on: { save: _vm.getData },
      }),
      _vm.$can("Create", "StockInversion")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Inversão de Estoque") },
            on: { click: _vm.showCreate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }