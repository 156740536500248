<template>
  <FormulateForm
    ref="form"
    name="form"
  >
    <e-sidebar
      id="sidebar-stock-inversion"
      :title="$t('Nova Inversão de Estoque')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveStockInversion"
      @hidden="showSidebar = false"
    >
      <template #content>
        <b-row>
          <b-col md="12">
            <e-store-combo
              id="inversion-store"
              v-model="productForm.storeId"
            />
          </b-col>
          <b-col md="12">
            <e-search-sku
              id="inversion-product-from"
              v-model="productForm.skuFromId"
              :label="$t('Produto de Origem')"
              :only-active="true"
            />
          </b-col>
          <b-col md="12">
            <e-search-sku
              id="inversion-product-to"
              v-model="productForm.skuToId"
              :label="$t('Produto de Destino')"
              :only-active="true"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="quantity"
              v-model="layoutForm.hasQuantityDiff"
              type="switch"
              :label="$t('Destino com quantidade diferente?')"
              :instruction="$t('STOCK_INVERSION.VIEW.INSTRUCTIONS.INPUT_HAS_DIFF')"
            />
          </b-col>

          <b-col md="6">
            <FormulateInput
              id="quantity"
              v-model.number="productForm.quantity"
              type="number"
              class="required"
              :label="$t('Quantidade')"
              validation="required|min:1"
            />
          </b-col>
          <b-col
            v-if="layoutForm.hasQuantityDiff"
            md="6"
          >
            <FormulateInput
              id="quantityTo"
              v-model.number="productForm.quantityTo"
              type="number"
              class="required"
              :label="$t('Quantidade Destino')"
              validation="required|min:1"
              :instruction="$t('STOCK_INVERSION.VIEW.INSTRUCTIONS.INPUT_QUANTITY_TO')"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="comments"
              v-model.number="productForm.comments"
              type="textarea"
              :label="$t('Observação')"
              class="required"
              validation="^required|min:25,length"
            />
          </b-col>
        </b-row>
      </template>
    </e-sidebar>
  </FormulateForm>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar, ESearchSku } from '@/views/components'
import { formulateHelper } from '@/mixins'
import { mapGetters } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

const getInitialForm = () => ({
  storeId: null,
  skuFromId: null,
  skuToId: null,
  quantity: null,
  quantityTo: null,
  comments: null,
})

const getInitialLayoutForm = () => ({
  hasQuantityDiff: false,
})

export default {
  components: { BRow, BCol, ESidebar, ESearchSku, EStoreCombo },

  mixins: [formulateHelper],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      productForm: getInitialForm(),
      layoutForm: getInitialLayoutForm(),
    }
  },

  computed: {
    ...mapGetters('common/products', ['getComboProducts']),
    isEdition() {
      return !!this.productForm.id
    },
  },

  watch: {
    showSidebar() {
      this.productForm = getInitialForm()
      this.layoutForm = getInitialLayoutForm()
      this.$refs.form.hideErrors()
    },
  },

  methods: {
    async saveStockInversion() {
      this.$refs.form.showErrors()
      if (this.$refs.form.isValid) {
        try {
          this.saving = true

          const payload = {
            ...this.productForm,
            quantityTo: this.layoutForm.hasQuantityDiff
              ? this.productForm.quantityTo
              : this.productForm.quantity,
          }

          const { data: result } = await this.$http.post('/api/stock-inversions', payload)
          this.showSuccess({ message: this.$t('Inversão criada com sucesso') })
          this.showSidebar = false
          this.$emit('save', result)
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        } finally {
          this.saving = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
