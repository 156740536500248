<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="filter-store"
            v-model="filters.storeId"
            :required="false"
            :placeholder="$t('Todas')"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="filter-product-from"
            v-model="filters.skuFromId"
            :required="false"
            :label="$t('Produto Origem')"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="filter-product-to"
            v-model="filters.skuToId"
            :required="false"
            :label="$t('Produto Destino')"
          />
        </b-col>

        <b-col md="4">
          <FormulateInput
            id="period"
            v-model="filters.period"
            type="date-range-picker"
            :label="$t('Período')"
            :time-picker="false"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="tablestockInversions"
        show-empty
        striped
        responsive
        class="mb-1 bordered"
        :empty-text="getEmptyTableMessage($tc('STOCK_INVERSION.NAME'), 'female')"
        :fields="fields"
        :items="stockInversions"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(store)="row">
          <div>
            <e-instructions
              :title="$t('Informações adicionais')"
              :target="`instruction_stock_${row.item.id}`"
              :instructions="[
                { text: `${$t('Responsável')}: ${row.item.createdBy}` },
                { text: `${$t('Obs')}: ${row.item.comments}` },
              ]"
            />

            {{ row.item.store | storeName }}
          </div>
        </template>
        <template #cell(skuFromName)="{ item }">
          <e-product-router-link
            :sku-id="item.skuFrom.id"
            :product-id="item.skuFrom.productId"
            :product-name="item.skuFrom.name"
            :ean="item.skuFrom.ean"
          />
        </template>
        <template #cell(skuToName)="{ item }">
          <e-product-router-link
            :sku-id="item.skuTo.id"
            :product-id="item.skuTo.productId"
            :product-name="item.skuTo.name"
            :ean="item.skuTo.ean"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <stock-inversion-sidebar
      ref="sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'StockInversion')"
      :main-tooltip="$t('Adicionar Inversão de Estoque')"
      @click="showCreate"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EInstructions,
  EPaginationSummary,
  EFilters,
  ESearchSku,
  EStoreCombo,
  EProductRouterLink,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import StockInversionSidebar from './components/StockInversionSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    EInstructions,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    StockInversionSidebar,
    ESearchSku,
    EStoreCombo,
    EProductRouterLink,
  },

  mixins: [],

  data() {
    return {
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/stock/stockInversion', ['stockInversions', 'sorting', 'paging', 'filters']),

    fields() {
      return [
        {
          label: this.$t('Data/Hora'),
          key: 'createdDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          tdClass: 'text-left',
          thStyle: { 'max-width': '250px', 'min-width': '150px' },
        },
        {
          label: this.$t('Produto Origem'),
          key: 'skuFromName',
          sortKey: 'skuFrom.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Produto Destino'),
          key: 'skuToName',
          sortKey: 'skuTo.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Qtd. Origem'),
          key: 'quantity',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Qtd. Destino'),
          key: 'quantityTo',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },

    today() {
      return new Date()
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/stock/stockInversion', [
      'fetchStockInversions',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStockInversions()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreate() {
      this.$refs.sidebar.showSidebar = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
